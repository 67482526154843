import {buildComponent, Component, ComponentConfig} from '../index';
import BaseComponent, {BaseComponentConfig} from '../BaseComponent';
import {ValueType} from '../../model/NativeAdObject';
import {trackLeadformCancel, trackLeadformImpression} from '../../../services/trackingService';
import Modal from '../util/Modal';
import {waitForInViewport} from '../../util/document-util';
import {getFormData, validateFormInputs} from '../../../util/inputValidation';
import {fetchBetter} from '../../../util/fetch';

export type LeadformConfig = {
	type: 'leadform'
	pages: ComponentConfig[]
} & BaseComponentConfig;

export default class Leadform extends BaseComponent {
	pages: Array<Component>;
	currentPage: number;
	contentPages: Array<HTMLElement>;
	#form?: HTMLFormElement;
	#modal?: Modal;

	constructor(config: LeadformConfig, renderConfig: RenderConfig) {
		super(config);

		this.pages = config.pages.map(c => buildComponent(c, renderConfig));
		this.currentPage = renderConfig.leadgen?.defaultPage ?? 0;
		this.contentPages = [];
	}

	render(renderContext: RenderContext): HTMLElement {
		this.#modal = renderContext.modal;

		const form = document.createElement('form');

		this.contentPages = this.pages.map((component: Component) => {
			const page = document.createElement('div');

			renderContext.style.style(page, {
				'display': 'flex',
				'flexDirection': 'column',
				'minHeight': '100%',
				'& > *': {
					'flex': 1
				}
			});
			page.style.display = 'none';
			page.appendChild(component.render(renderContext));
			form.appendChild(page);

			return page;
		});

		this.goToPage(this.currentPage);

		this.#form = form;

		this.#trackEvents(form, renderContext);

		return form;
	}

	close(): void {
		this.#modal?.hide();
		this.goToPage(0);
	}

	goToPage(pageNumber: number): void {
		this.contentPages[this.currentPage].style.display = 'none';
		this.currentPage = pageNumber;
		this.contentPages[this.currentPage].style.display = '';
	}

	async submit(renderContext: RenderContext): Promise<boolean> {
		if (!this.#form) {
			throw new Error('Tried to submit leadform before rendering');
		}

		if (!validateFormInputs(renderContext.componentTree)) {
			return false;
		}

		if (typeof renderContext.renderConfig.onLeadformSubmit === 'function') {
			return renderContext.renderConfig.onLeadformSubmit(renderContext, getFormData(renderContext.componentTree));
		}

		return this.#sendDataToUrl(renderContext);
	}

	async #sendDataToUrl(renderContext: RenderContext): Promise<boolean> {
		const url = renderContext.adObject.leadgen?.submitUrl;

		if (!url) {
			throw new Error('Missing leadform submit url');
		}

		const response = await fetchBetter(url, {
			method: 'POST',
			body: JSON.stringify({
				output: renderContext.adObject.leadgen!.output,
				formData: getFormData(renderContext.componentTree),
				auctionId: renderContext.renderConfig.context?.slot?.adResponse?.auctionId
			}),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		if (response.status >= 400) {
			throw new Error(`Leadform submit returned an error code | Status code: ${response.status}`);
		}

		return true;
	}

	#trackEvents(element: HTMLElement, renderContext: RenderContext) {
		waitForInViewport(element).then(() => {
			trackLeadformImpression(renderContext, this, renderContext.adObject.getValue(ValueType.callToAction));
		});

		renderContext.modal?.addEventListener('hide', () => {
			trackLeadformCancel(renderContext, this);
		});
	}
}

export async function post(url: string, body: string, timeout: number = 5000) {
	const abortContr = new AbortController(),
		timeoutRef = setTimeout(() => {
			abortContr.abort();
		}, timeout);

	try {
		return await fetch(url, {
			method: 'POST',
			body,
			signal: abortContr.signal,
			headers: {
				'Content-Type': 'application/json'
			}
		});
	} finally {
		clearTimeout(timeoutRef);
	}
}

import BaseComponent from './BaseComponent';
import logger from '../../util/logger';

export default class Unknown extends BaseComponent {
	constructor(config: unknown) {
		super({'type': 'unknown'});

		logger.warn('Trying to render unknown component', config);
	}

	render(): DocumentFragment {
		return document.createDocumentFragment();
	}
}

import createEmotion, {CSSInterpolation, Emotion} from '@emotion/css/create-instance';

export default class StyleBase {
	#emotion: Emotion;

	constructor(container: HTMLElement | DocumentFragment) {
		this.#emotion = createEmotion({
			'key': 'nts',
			'speedy': false, // Prevents issues with the detached container, though it is slower
			container
		});
	}

	style(el: HTMLElement, styles: CSSInterpolation): void {
		el.classList.add(this.getClassName(styles));
	}

	getClassName(styles: CSSInterpolation): string {
		return this.#emotion.css(styles);
	}
}



import BaseLabel, {BaseLabelConfig} from './subcomponents/BaseLabel';

export type ValidationLabelConfig = BaseLabelConfig & {
	type: 'validationLabel'
	errorId: string
};

export default class ValidationLabel extends BaseLabel {
	errorId: string;
	labelElement: HTMLElement;

	constructor(config: ValidationLabelConfig) {
		super(config);

		this.errorId = config.errorId;
	}

	render(renderContext: RenderContext): HTMLElement {
		const element = super.render(renderContext);

		element.style.display = 'none';

		this.labelElement = element;

		return element;
	}

	showError(errorMessage: string): void {
		this.setText(this.formatText(errorMessage));
		this.labelElement.style.display = '';
	}

	hideError(): void {
		this.labelElement.style.display = 'none';
	}
}

import {DEFAULT_COLORS, getBorderString, getColorString, getSpacingString} from '../util/style-util';
import {buildComponent, Component, ComponentConfig} from './index';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';


export type RowConfig = {
	type: 'row'
	padding?: Spacing
	backgroundColor?: Color
	width?: number
	height?: number
	cornerRadius?: number
	borderColor?: Color
	borderWidth?: number
	views: Array<ComponentConfig>
} & BaseComponentConfig;

export default class Row extends BaseComponent {
	padding?: Spacing;
	backgroundColor?: Color;
	width?: number;
	height?: number;
	cornerRadius?: number;
	borderColor?: Color;
	borderWidth?: number;
	views: Array<Component>;

	constructor(config: RowConfig, renderConfig: RenderConfig) {
		super(config);

		this.padding = config.padding;
		this.backgroundColor = config.backgroundColor;
		this.width = config.width;
		this.height = config.height;
		this.cornerRadius = config.cornerRadius;
		this.borderColor = config.borderColor;
		this.borderWidth = config.borderWidth;
		this.views = config.views.map(c => buildComponent(c, renderConfig));
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'display': 'flex',
			'flexDirection': 'row',
			'alignItems': 'center',
			'width': '100%',
			'boxSizing': 'border-box',
			'overflow': 'hidden',
			'pointerEvents': 'none', // Allow click events to go through layers
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'padding': getSpacingString(this.padding)
		});

		if (typeof this.width === 'number') {
			renderContext.style.style(el, {
				'width': `${this.width}px`
			});
		}

		if (typeof this.height === 'number') {
			renderContext.style.style(el, {
				'height': `${this.height}px`
			});
		}

		if (typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined') {
			renderContext.style.style(el, {
				'border': getBorderString(renderContext, {
					'width': this.borderWidth,
					'color': this.borderColor
				})
			});
		}

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(el, {
				'borderRadius': `${this.cornerRadius}px`
			});
		}

		const totalWeight = this.views.reduce((agg, component) => agg + (component.weight ?? 0), 0),
			percPerWeight = totalWeight > 0 ? 100 / totalWeight : 100;

		this.views.forEach((component: Component) => {
			const compEl = component.render(renderContext);

			if (compEl instanceof HTMLElement) {
				if (typeof component.weight !== 'undefined') {
					renderContext.style.style(compEl, {
						'flexBasis': `${percPerWeight * component.weight}%`
					});
				}

				// @ts-ignore: width might not exist, well duh
				if (typeof component.width !== 'undefined') {
					renderContext.style.style(compEl, {
						'flexGrow': '0',
						'flexShrink': '0'
					});
				}
			}

			el.appendChild(compEl);
		});

		return el;
	}
}

import Label, {LabelConfig} from '../Label';
import BaseButton, {BaseButtonConfig} from '../subcomponents/BaseButton';
import {findInTree} from '../../util/component-util';
import Leadform from './Leadform';
import {trackLeadformClose} from '../../../services/trackingService';

export type LeadformCloseConfig = {
	type: 'leadformClose'
	label: LabelConfig
} & BaseButtonConfig;

export default class LeadformClose extends BaseButton {
	label: Label;

	constructor(config: LeadformCloseConfig) {
		super(config);

		this.label = new Label(config.label, true);
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = super.render(renderContext);

		el.appendChild(this.label.render(renderContext));
		el.addEventListener('click', () => {
			const leadformComponent = findInTree<Leadform>(renderContext.componentTree, (c) => c.type === 'leadform');

			if (leadformComponent) {
				trackLeadformClose(renderContext, leadformComponent, this.label);
				leadformComponent.close();
			}
		});

		return el;
	}
}

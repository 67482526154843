import Label, {LabelConfig} from '../Label';
import BaseButton, {BaseButtonConfig} from '../subcomponents/BaseButton';
import {findInTree} from '../../util/component-util';
import Leadform from './Leadform';
import {trackLeadformImpression, trackLeadformSubmit, trackLeadformSubmitFailure} from '../../../services/trackingService';
import ValidationLabel from '../ValidationLabel';
import logger from '../../../util/logger';

export type LeadformSubmitConfig = {
	type: 'leadformSubmit'
	label: LabelConfig
	pageNumber: number
	validation?: Validation
} & BaseButtonConfig;

export default class LeadformSubmit extends BaseButton {
	label: Label;
	pageNumber: number;
	validation?: Validation;

	constructor(config: LeadformSubmitConfig) {
		super(config);

		this.label = new Label(config.label, true);
		this.pageNumber = config.pageNumber ?? -1;
		this.validation = config.validation;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = super.render(renderContext);

		el.appendChild(this.label.render(renderContext));
		el.addEventListener('click', () => this.#onClick(renderContext, el));

		return el;
	}

	async #onClick(renderContext: RenderContext, button: HTMLElement) {
		const leadformComponent = findInTree<Leadform>(renderContext.componentTree, (c) => c.type === 'leadform');

		if (!leadformComponent)
			return;

		try {
			button.setAttribute('disabled', '');

			const success = await leadformComponent.submit(renderContext);

			if (success) {
				this.#onSuccess(leadformComponent, renderContext);
			}
		} catch (e) {
			logger.error('Something went wrong trying to submit the leadform.', e);
			this.#onError(leadformComponent, renderContext);
		} finally {
			button.removeAttribute('disabled');
		}
	}

	#onError(leadformComponent: Leadform, renderContext: RenderContext) {
		trackLeadformSubmitFailure(renderContext, leadformComponent, this.label);
		this.#getValidationLabel(renderContext)?.showError(this.validation?.messageValidation ?? '');
	}

	#onSuccess(leadformComponent: Leadform, renderContext: RenderContext) {
		this.#getValidationLabel(renderContext)?.hideError();
		trackLeadformSubmit(renderContext, leadformComponent, this.label);

		if (this.pageNumber < 0) {
			leadformComponent.close();
		} else {
			leadformComponent.goToPage(this.pageNumber);
			trackLeadformImpression(renderContext, leadformComponent, this.label);
		}
	}

	#getValidationLabel(renderContext: RenderContext): ValidationLabel | null {
		if (!this.validation)
			return null;

		return findInTree<ValidationLabel>(renderContext.componentTree, (c: ValidationLabel) => c.type === 'validationLabel' && c.errorId === this.validation?.id);
	}
}

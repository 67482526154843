import BaseComponent, {BaseComponentConfig} from './BaseComponent';
import {buildComponent, Component, ComponentConfig} from './index';

export type DSASettings = {
	adRender?: number
	behalf?: string
	paid?: string
	transparency?: Array<{
		domain: string;
		dsaParams: number[];
	}>
}

export type DSAConfig = {
	type: 'dsa'
	view: ComponentConfig;
} & BaseComponentConfig;

export default class DSA extends BaseComponent {
	dsaSettings?: DSASettings;
	view: Component;

	constructor(config: DSAConfig, renderConfig: RenderConfig) {
		super(config);

		this.dsaSettings = renderConfig.context?.slot?.adResponse?.dsa;
		this.view = buildComponent(config.view, renderConfig);
	}

	render(renderContext: RenderContext): HTMLElement | DocumentFragment {
		if (!this.dsaSettings || Object.keys(this.dsaSettings).length === 0 || !renderContext.adObject.privacyLink) {
			return document.createDocumentFragment();
		}

		const el = document.createElement('a');

		renderContext.style.style(el, {
			'pointerEvents': 'all'
		});

		el.setAttribute('href', renderContext.adObject.privacyLink);
		el.setAttribute('target', '_blank');
		el.setAttribute('rel', 'noopener noreferrer nofollow');
		el.appendChild(this.view.render(renderContext));

		return el;
	}
}

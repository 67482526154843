import BaseComponent, {BaseComponentConfig} from './BaseComponent';

export type SpacerConfig = {
	type: 'spacer'
} & BaseComponentConfig;

export default class Spacer extends BaseComponent {
	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'flexGrow': '1',
			'pointerEvents': 'none' // Allow clicking through spacers
		});

		return el;
	}
}

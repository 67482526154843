import {findInTree, ShowOnStates, showOnVideoStates} from '../util/component-util';
import Video, {VideoEvent, VideoState} from './Video';
import BaseButton, {BaseButtonConfig} from './subcomponents/BaseButton';

export type VideoPlayPauseButtonConfig = BaseButtonConfig & {
	type: 'videoPlayPause'
	showOn?: ShowOnStates,
	playIcon?: string,
	pauseIcon?: string,
};

const PLAY_ICON = '<svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" viewBox="0 0 16 16"> <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" fill="white"></path></svg>',
	PAUSE_ICON = '<svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" viewBox="0 0 16 16"> <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" fill="white"></path></svg>';

export default class VideoPlayPauseButton extends BaseButton {
	showOn?: ShowOnStates;
	playIcon?: string;
	pauseIcon?: string;

	constructor(config: VideoPlayPauseButtonConfig) {
		super(config);

		this.showOn = config.showOn;
		this.playIcon = config.playIcon;
		this.pauseIcon = config.pauseIcon;
	}

	// @ts-ignore not an exact cover for Button.render()
	render(renderContext: RenderContext): HTMLElement {
		const video = findInTree<Video>(renderContext.componentTree, (c) => c.type === 'video');

		if (video == null) {
			throw new Error('Could not find a video for the VideoPlayPauseButton');
		}

		const btn = super.render(renderContext),
			icon = document.createElement('span');

		icon.innerHTML = this.#getIcon(video);
		btn.setAttribute('aria-label', this.#getAriaLabel(video));

		video.events.addEventListener(VideoEvent.StateChanged, () => {
			btn.setAttribute('aria-label', this.#getAriaLabel(video));
			icon.innerHTML = this.#getIcon(video);
		});

		btn.appendChild(icon);
		btn.addEventListener('click', (e) => {
			if (video.state === VideoState.Playing) {
				video.pause();
			} else {
				video.play();
			}

			e.stopPropagation();
			e.preventDefault();
		});

		if (Array.isArray(this.showOn)) {
			showOnVideoStates(btn, video, this.showOn);
		}

		return btn;
	}

	#getIcon(video: Video) {
		if (video.state === VideoState.Playing) {
			return this.pauseIcon ?? PAUSE_ICON;
		}

		return this.playIcon ?? PLAY_ICON;
	}

	#getAriaLabel(video: Video) {
		if (video.state === VideoState.Playing) {
			return 'pause';
		}

		return 'play';
	}
}

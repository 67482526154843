import {DEFAULT_COLORS, getColorString, getSpacingString} from '../util/style-util';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';


export type LineConfig = {
	type: 'line'
	padding?: Spacing
	lineColor?: Color
} & BaseComponentConfig;

export default class Line extends BaseComponent {
	margin?: Spacing;
	lineColor?: Color;

	constructor(config: LineConfig) {
		super(config);

		this.margin = config.padding; // Template provides padding, but it's margin around the line. It's intended as padding for the line "container"
		this.lineColor = config.lineColor;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'width': '100%',
			'minHeight': '1px',
			'backgroundColor': getColorString(renderContext, this.lineColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'margin': getSpacingString(this.margin)
		});

		return el;
	}
}

import {DEFAULT_COLORS, getBorderString, getColorString, getFontFamily, getShadowString, getSpacingString} from '../../util/style-util';
import NativeAdObject, {ValueType} from '../../model/NativeAdObject';
import BaseComponent, {BaseComponentConfig} from '../BaseComponent';
import {CSSInterpolation} from '@emotion/css/create-instance';

export type BaseLabelConfig = {
	margin?: Spacing
	padding?: Spacing
	height?: number
	color: Color
	fontSize: number
	fontSizeWeb?: number
	minimumTextSize?: number
	minimumScaleFactor?: number
	fontWeight?: number
	fontWeightWeb?: number
	font?: string
	fontWeb?: string
	lineHeight?: number
	lineHeightWeb?: number
	lineLimit?: number
	uppercase?: boolean
	textAlign?: string
	textFormatWeb?: string
	valueType?: ValueType
	backgroundColor?: Color
	borderColor?: Color
	borderWidth?: number
	cornerRadius?: number
	shadow?: Shadow
	onHover?: {
		color?: Color
		fontWeight?: number
		fontWeightWeb?: number
		lineHeight?: number
		lineHeightWeb?: number
		backgroundColor?: Color
		shadow?: Shadow
	}
} & BaseComponentConfig;

export default class BaseLabel extends BaseComponent {
	margin?: Spacing;
	padding?: Spacing;
	textFormat: string;
	valueType: ValueType;
	color: Color;
	fontSize: number;
	fontWeight?: number;
	font?: string;
	lineHeight?: number;
	uppercase?: boolean;
	lineLimit?: number;
	minimumTextSize: number | null;
	height?: number;
	backgroundColor?: Color;
	borderWidth?: number;
	borderColor?: Color;
	cornerRadius?: number;
	shadow?: Shadow;
	textAlign?: string;
	onHover?: {
		color?: Color
		fontWeight?: number
		lineHeight?: number
		backgroundColor?: Color
		shadow?: Shadow
	};

	readonly #isSubcomponent: boolean;

	#wrapper: HTMLElement;
	#textEl: HTMLElement;

	constructor(config: BaseLabelConfig, isSubcomponent = false) {
		super(config);

		this.#isSubcomponent = isSubcomponent;

		this.margin = config.margin;
		this.padding = config.padding;

		this.textFormat = config.textFormatWeb ?? '%s';
		this.valueType = config.valueType ?? ValueType.none;

		this.color = config.color;
		this.fontSize = config.fontSizeWeb ?? config.fontSize;
		this.fontWeight = config.fontWeightWeb ?? config.fontWeight;
		this.font = config.fontWeb ?? config.font;
		this.lineHeight = config.lineHeightWeb ?? config.lineHeight;
		this.shadow = config.shadow;
		this.uppercase = config.uppercase;

		this.lineLimit = config.lineLimit;
		this.minimumTextSize = BaseLabel.#getMinimumFontSize(this.fontSize, config.minimumTextSize, config.minimumScaleFactor);

		this.height = config.height;
		this.backgroundColor = config.backgroundColor;
		this.borderWidth = config.borderWidth;
		this.borderColor = config.borderColor;
		this.cornerRadius = config.cornerRadius;
		this.textAlign = config.textAlign;

		if (config.onHover) {
			this.onHover = {
				color: config.onHover.color,
				fontWeight: config.onHover.fontWeightWeb ?? config.onHover.fontWeight,
				lineHeight: config.onHover.lineHeightWeb ?? config.onHover.lineHeight,
				backgroundColor: config.onHover.backgroundColor,
				shadow: config.onHover.shadow,
			};
		}
	}

	static #getMinimumFontSize(fontSize: number, minimumTextSize?: number, minimumScaleFactor?: number): number | null {
		if (typeof minimumTextSize === 'number') {
			return minimumTextSize;
		}

		if (typeof minimumScaleFactor === 'number') {
			return minimumScaleFactor * fontSize;
		}
		return null;
	}
	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('span'),
			textEl = document.createElement('span');

		renderContext.style.style(el, {
			'display': 'inline-block',
			'boxSizing': 'border-box',
			'lineHeight': '1'
		});

		renderContext.style.style(el, {
			'maxWidth': `calc(100% - ${(this.margin?.left ?? 0) + (this.margin?.right ?? 0)}px)`,
			'margin': getSpacingString(this.margin),
			'padding': getSpacingString(this.padding),
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'color': getColorString(renderContext, this.color) ?? DEFAULT_COLORS.BLACK,
			'fontFamily': getFontFamily(this.font),
			'fontWeight': `${this.fontWeight ?? 'normal'}`,
			'fontSize': `${this.fontSize}px`,
			'border': typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined' ? getBorderString(renderContext, {
				'width': this.borderWidth,
				'color': this.borderColor
			}) : 'none'
		});

		renderContext.style.style(textEl, {
			'display': 'inline-block',
			'overflow': 'hidden',
			'textOverflow': 'ellipsis',
			'boxSizing': 'border-box',
		});

		renderContext.style.style(textEl, {
			'lineHeight': typeof this.lineHeight === 'number' ? `${this.lineHeight}` : 'normal',
			'fontSize': `${this.fontSize}px` // Placed on both elements so it can scale
		});

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(el, {
				'borderRadius': `${this.cornerRadius}px`
			});
		}

		if (this.textAlign) {
			// @ts-ignore: TextAlign != string
			renderContext.style.style(textEl, {
				'textAlign': this.textAlign
			});
		}

		if (typeof this.height === 'number') {
			renderContext.style.style(el, {
				'height': `${this.height}px`
			});
		}

		if (this.uppercase) {
			renderContext.style.style(textEl, {
				'textTransform': 'uppercase'
			});
		}

		if (typeof this.shadow === 'object') {
			renderContext.style.style(textEl, {
				'textShadow': getShadowString(renderContext, this.shadow),
			});
		}

		if (typeof this.lineLimit === 'number') {
			if (this.lineLimit === 1) { // If it's only one line, only use width so ellipsis actually works
				renderContext.style.style(textEl, {
					'width': '100%', // TODO check max width?
					'whiteSpace': 'nowrap',
				});
			} else { // If it's multiple lines, count lines and add extra room for rounding and stuff below the baseline
				renderContext.style.style(textEl, {
					'maxHeight': `calc(${this.lineLimit}em * ${this.lineHeight ?? 1.2})`,
				});
			}
		}

		if (this.onHover) {
			let hoverStyles: CSSInterpolation = {};
			if (typeof this.onHover.color !== 'undefined') {
				hoverStyles.color = getColorString(renderContext, this.onHover.color) as string;
			}
			if (typeof this.onHover.backgroundColor !== 'undefined') {
				hoverStyles.backgroundColor = getColorString(renderContext, this.onHover.backgroundColor) as string;
			}
			if (typeof this.onHover.fontWeight === 'number') {
				hoverStyles.fontWeight = `${this.onHover.fontWeight}`;
			}

			if (Object.keys(hoverStyles).length > 0) {
				renderContext.style.style(el, {
					[this.#isSubcomponent ? '*:hover > &' : '&:hover']: hoverStyles
				});
			}

			hoverStyles = {};

			if (typeof this.onHover.lineHeight === 'number') {
				hoverStyles.lineHeight = `${this.onHover.lineHeight}`;
			}

			if (typeof this.onHover.shadow === 'object') {
				hoverStyles.textShadow = getShadowString(renderContext, this.onHover.shadow);
			}

			if (Object.keys(hoverStyles).length > 0) {
				renderContext.style.style(textEl, {
					[this.#isSubcomponent ? '*:hover > span > &' : '&:hover']: hoverStyles
				});
			}
		}

		el.appendChild(textEl);

		this.#wrapper = el;
		this.#textEl = textEl;

		this.setText(this.getText(renderContext.adObject));

		return el;
	}

	setText(text: Node) {
		this.#textEl.innerHTML = '';
		this.#textEl.appendChild(text);

		if (this.minimumTextSize !== null) {
			this.#scaleFontSize(this.#wrapper, this.#textEl, this.fontSize, this.minimumTextSize);
		}
	}

	getText(adObject: NativeAdObject, customValueTypeIndex?: number): Node {
		const value = adObject.getValue(this.valueType, customValueTypeIndex);

		return this.formatText(value);
	}

	formatText(labelText: string) {
		const formatted = this.textFormat.replace('%s', labelText),
			container = document.createDocumentFragment();

		formatted.split(/\n|\\n/).forEach((text, idx, arr) => {
			container.appendChild(document.createTextNode(text));

			if (idx < arr.length - 1) {
				container.appendChild(document.createElement('br'));
			}
		});

		return container;
	}
	#scaleFontSize(el: HTMLElement, textEl: HTMLElement, curSize: number, minimumSize: number) {
		textEl.style.visibility = 'hidden';

		window.setTimeout(() => {
			const currWidth = textEl.scrollWidth,
				currHeight = textEl.scrollHeight,
				{width: allowedWidth, height: allowedHeight} = textEl.getBoundingClientRect();

			if (currWidth > Math.round(allowedWidth) || currHeight > Math.round(allowedHeight)) {
				const newSize = curSize - 1;

				if (newSize >= minimumSize) {
					textEl.style.fontSize = `${newSize}px`;
					el.style.fontSize = `${newSize}px`;
					this.#scaleFontSize(el, textEl, newSize, minimumSize);

					return;
				}
			}

			textEl.style.visibility = '';
		}, 0);
	}
}

import {buildComponent, Component, ComponentConfig} from '../index';
import logger from '../../../util/logger';

export type ModalConfig = {
	views: ComponentConfig[]
};

export default class Modal {
	type: string = 'modal';
	views: Array<Component>;

	#renderContext?: RenderContext;
	#modalContainer?: HTMLElement;
	#eventListeners: Record<string, Array<() => void>> = {};

	constructor(config: ModalConfig, renderConfig: RenderConfig) {
		this.views = config.views.map(c => buildComponent(c, renderConfig));
	}

	render(renderContext: RenderContext): HTMLElement {
		this.#renderContext = renderContext;

		const modalContainer = document.createElement('div');

		renderContext.style.style(modalContainer, {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			zIndex: 1999999999, // to be above nu.nl navigation bar
			backgroundColor: 'rgba(0,0,0,0.5)',
		});
		modalContainer.style.display = 'none';

		const modal = document.createElement('div');

		renderContext.style.style(modal, {
			'position': 'fixed',
			'left': '50%',
			'transform': 'translateX(-50%)',

			'height': 'calc(100% - 64px)',
			'maxWidth': '768px',
			'maxHeight': '900px',

			'display': 'flex',
			'flexDirection': 'column',
			'overflow': 'hidden',

			'backgroundColor': renderContext.renderConfig.darkMode ? '#151515' : '#fff',
			'border': '1px solid #888',

			'@media only screen and (min-width: 769px)': {
				'top': '50%',
				'transform': 'translate(-50%, -50%)',
				'borderRadius': '16px'
			},
			'@media only screen and (max-width: 768px)': {
				'width': '100%',
				'bottom': 0,
				'borderRadius': '16px 16px 0px 0px',
			}
		});

		modalContainer.appendChild(modal);

		const closeButton = document.createElement('button');

		renderContext.style.style(closeButton, {
			'border': 'none',
			'background': 'none',
			'color': renderContext.renderConfig.darkMode ? '#fff' : '#aaa',
			'fontSize': '22px',
			'padding': '11px 16px 11px 16px',
			'alignSelf': 'flex-start',
			'&:hover': {
				'color': 'black',
				'textDecoration': 'none',
				'cursor': 'pointer',
			},
		});
		closeButton.type = 'button';
		closeButton.innerHTML = '&#x2715;';
		closeButton.addEventListener('click', () => {
			this.hide();
		});

		modal.appendChild(closeButton);

		const contentContainer = document.createElement('div');

		renderContext.style.style(contentContainer, {
			display: 'flex',
			flexGrow: 1,
			overflow: 'auto'
		});

		modal.appendChild(contentContainer);

		const subRenderContext: RenderContext = {
			...renderContext,
			componentTree: this,
			modal: this
		};

		this.views.forEach((component: Component) => {
			contentContainer.appendChild(
				component.render(subRenderContext)
			);
		});

		this.#modalContainer = modalContainer;

		// Intercept clicks so they don't bubble to the parent ad for click tracking
		modalContainer.addEventListener('click', (e) => {
			e.stopPropagation();
		});

		return modalContainer;
	}

	show() {
		if (!this.#modalContainer) {
			throw new Error('Tried to show a modal before rendering');
		}

		this.#modalContainer.style.display = '';
		document.body.style.overflow = 'hidden';

		this.#eventListeners.show?.forEach(cb => {
			try {
				cb();
			} catch (e) {
				logger.error('Modal show event listener threw an error.', e);
			}
		});

		try {
			this.#renderContext?.renderConfig?.onModalOpen?.(this.#renderContext, this);
		} catch (e) {
			logger.error('onModalOpen threw an error.', e);
		}
	}

	hide() {
		if (!this.#modalContainer) {
			throw new Error('Tried to hide a modal before rendering');
		}

		this.#modalContainer.style.display = 'none';
		document.body.style.overflow = '';

		this.#eventListeners.hide?.forEach(cb => {
			try {
				cb();
			} catch (e) {
				logger.error('Modal hide event listener threw an error.', e);
			}
		});

		try {
			this.#renderContext?.renderConfig?.onModalClose?.(this.#renderContext, this);
		} catch (e) {
			logger.error('onModalClose threw an error.', e);
		}
	}

	addEventListener(type: 'show' | 'hide', callback: () => void): void {
		if (!this.#eventListeners[type]) {
			this.#eventListeners[type] = [];
		}

		this.#eventListeners[type].push(callback);
	}
}

export type BaseComponentConfig = {
	type: string,
	weight?: number
};

export default abstract class BaseComponent {
	type: string;
	weight?: number;

	constructor(config: BaseComponentConfig) {
		this.type = config.type;
		this.weight = config.weight;
	}

	abstract render(renderContext: RenderContext): HTMLElement | DocumentFragment;
}

export const DEFAULT_COLORS = {
		TRANSPARENT: '#00000000',
		BLACK: '#000',
	},
	DEFAULT_FONT_FAMILY = 'sans-serif';

export function getColorString(renderContext: RenderContext, config?: Color): string | null {
	const mode = renderContext.renderConfig?.darkMode ? 'dark' : 'light';

	if (config?.[mode]) {
		return _argbToRgba(config[mode] as string);
	}

	return null;
}

function _argbToRgba(colorStr: string): string {
	if (colorStr.length === 9) {
		return `#${colorStr.slice(3)}${colorStr.slice(1, 3)}`;
	}

	if (colorStr.length === 5) {
		return `#${colorStr.slice(2)}${colorStr.slice(1, 2)}`;
	}

	return colorStr;
}


export function getSpacingString(config?: Spacing): string {
	if (!config || Object.values(config).every(v => v === 0)) {
		return '0';
	}

	return `${config.top ?? 0}px ${config.right ?? 0}px ${config.bottom ?? 0}px ${config.left ?? 0}px`;
}

export function getShadowString(renderContext: RenderContext, config: Shadow): string {
	return `${config.offset?.x ?? 0}px ${config.offset?.y ?? 0}px ${config.blur ?? 0}px ${getColorString(renderContext, config.color) ?? DEFAULT_COLORS.BLACK}`;
}

export function getFontFamily(font?: string): string {
	if (font) {
		return `${font}, ${DEFAULT_FONT_FAMILY}`;
	}

	return DEFAULT_FONT_FAMILY;
}

export function getBorderString(renderContext: RenderContext, config: Border): string {
	return `${config.width ?? 0}px solid ${getColorString(renderContext, config.color) ?? DEFAULT_COLORS.TRANSPARENT}`;
}

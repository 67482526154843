import {findInTree, ShowOnStates, showOnVideoStates} from '../util/component-util';
import Video, {VideoEvent} from './Video';
import BaseButton, {BaseButtonConfig} from './subcomponents/BaseButton';

export type VideoMuteButtonConfig = BaseButtonConfig & {
	type: 'videoMute'
	showOn?: ShowOnStates
	muteIcon?: string
	unmuteIcon?: string
};

const MUTE_ICON = '<svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" viewBox="0 0 16 16"> <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" fill="white"></path></svg>',
	UNMUTE_ICON = '<svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" viewBox="0 0 16 16"> <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" fill="white"></path> <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" fill="white"></path> <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" fill="white"></path></svg>';

export default class VideoMuteButton extends BaseButton {
	showOn?: ShowOnStates;
	muteIcon?: string;
	unmuteIcon?: string;

	constructor(config: VideoMuteButtonConfig) {
		super(config);

		this.showOn = config.showOn;
		this.muteIcon = config.muteIcon;
		this.unmuteIcon = config.unmuteIcon;
	}

	// @ts-ignore not an exact cover for Button.render()
	render(renderContext: RenderContext): HTMLElement {
		const video = findInTree<Video>(renderContext.componentTree, (c) => c.type === 'video');

		if (video == null) {
			throw new Error('Could not find a video for the VideoMuteButton');
		}

		const btn = super.render(renderContext),
			icon = document.createElement('span');

		icon.innerHTML = this.#getIcon(video);
		btn.setAttribute('aria-label', this.#getAriaLabel(video));

		video.events.addEventListener(VideoEvent.VolumeChanged, () => {
			icon.innerHTML = this.#getIcon(video);
			btn.setAttribute('aria-label', this.#getAriaLabel(video));
		});

		btn.appendChild(icon);
		btn.addEventListener('click', (e) => {
			if (video.muted) {
				video.unmute();
			} else {
				video.mute();
			}

			e.stopPropagation();
			e.preventDefault();
		});

		if (Array.isArray(this.showOn)) {
			showOnVideoStates(btn, video, this.showOn);
		}

		return btn;
	}

	#getIcon(video: Video) {
		if (video.muted) {
			return this.muteIcon ?? MUTE_ICON;
		}

		return this.unmuteIcon ?? UNMUTE_ICON;
	}

	#getAriaLabel(video: Video) {
		if (video.muted) {
			return 'unmute';
		}

		return 'mute';
	}
}

import {ValueType} from '../renderer/model/NativeAdObject';
import Modal from '../renderer/components/util/Modal';
import {ComponentConfig} from '../renderer/components';
import {parseTemplate} from '../renderer';
import {getTemplate} from '../index';
import {fireClickTrackers} from './trackingService';

export function attachClickHandlers(component: HTMLElement | DocumentFragment, renderContext: RenderContext): HTMLElement | DocumentFragment {
	const element = wrapComponent(component, renderContext);

	_addClickListeners(element, renderContext);

	return element;
}

function wrapComponent(component: HTMLElement | DocumentFragment, renderContext: RenderContext) {
	if (renderContext.adObject.leadgen) {
		if (!renderContext.adObject.leadgen.template) {
			return component;
		}

		return _buildLeadform(component, renderContext);
	}

	const clickUrl = renderContext.adObject.getValue(ValueType.clickUrl);

	if (clickUrl) {
		return _buildLink(clickUrl, component);
	}

	return component;
}

function _addClickListeners(element: HTMLElement | DocumentFragment, renderContext: RenderContext) {
	// Fetched outside as render config might update by the time we click
	const onClick = renderContext.renderConfig.onClick;

	if (typeof onClick === 'function') {
		element.addEventListener('click', (e) => {
			onClick?.(e, renderContext);
		});
	}
}

function _buildLink(href: string, component: HTMLElement | DocumentFragment) {
	const linkEl: HTMLElement = document.createElement('a');

	linkEl.setAttribute('href', href);
	linkEl.setAttribute('target', '_blank');
	linkEl.setAttribute('rel', 'noopener noreferrer nofollow');
	linkEl.appendChild(component);
	linkEl.style.textDecoration = 'none';

	return linkEl;
}

function _buildModal(element: HTMLElement | DocumentFragment, renderContext: RenderContext, content: ComponentConfig[]) {
	const modal = new Modal({
			views: content
		}, renderContext.renderConfig),
		fragment = document.createDocumentFragment(),
		linkEl: HTMLElement = document.createElement('a');

	linkEl.appendChild(element);
	linkEl.style.textDecoration = 'none';

	linkEl.addEventListener('click', (event) => {
		// We can add a condition here if it turns out other modals shouldn't track clicks when opening
		fireClickTrackers(renderContext.adObject);

		modal.show();
		event.stopPropagation();
	});

	fragment.appendChild(linkEl);
	fragment.appendChild(modal.render(renderContext));

	return fragment;
}

function _buildLeadform(element: HTMLElement | DocumentFragment, renderContext: RenderContext) {
	const leadformConfig = _getComponentConfig(renderContext.adObject.leadgen!.template, renderContext);

	return _buildModal(element, renderContext, [leadformConfig]);
}

function _getComponentConfig(templateName: string, renderContext: RenderContext): ComponentConfig {
	const template : NativeTemplate | null = getTemplate(templateName, renderContext.renderConfig);

	if (template === null) {
		throw new Error('Could not find native template');
	}

	return parseTemplate(template, renderContext.renderConfig);
}

import {getColorString} from '../util/style-util';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';


export type LinearGradientConfig = {
	type: 'linearGradient'
	start?: Point
	stop?: Point
	colorStops: Array<{
		position: number
		color: Color
	}>
} & BaseComponentConfig;

export default class LinearGradient extends BaseComponent {
	start?: Point;
	stop?: Point;
	colorStops: Array<{
		position: number
		color: Color
	}>;

	constructor(config: LinearGradientConfig) {
		super(config);

		this.start = config.start;
		this.stop = config.stop;
		this.colorStops = config.colorStops;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'width': '100%',
			'height': '100%',
			'background': this.#buildGradient(renderContext)
		});

		return el;
	}

	#buildGradient(renderContext: RenderContext): string {
		return `linear-gradient(${this.#calculateAngle()}rad, ${this.#calculateColorStops(renderContext).join(', ')})`;
	}

	#calculateColorStops(renderContext: RenderContext): Array<string> {
		return this.colorStops.map(({position, color}) => `${getColorString(renderContext, color)} ${position * 100}%`);
	}

	// Returns radians
	#calculateAngle(): number {
		const x = (this.stop?.x ?? Infinity) - (this.start?.x ?? 0),
			y = (this.stop?.y ?? Infinity) - (this.start?.y ?? 0),
			rads = Math.atan2(y, x),
			quarterTurn = Math.PI / 2;
		/*
			Viewport angle goes clockwise and starts vertically up.
			Mathematical angle goes counterclockwise and starts horizontally right.
			So we correct. Negate to turn clockwise, and -90deg/-0.5𝜋rad to correct 0 angle
		*/
		return -rads - quarterTurn;
	}
}

import {ValueType} from '../model/NativeAdObject';
import IMAManager from '../util/ima-manager';
import BaseComponent, {BaseComponentConfig} from './BaseComponent';

export type VideoConfig = {
	type: 'video'
	autoPlay?: boolean
	startMuted?: boolean
} & BaseComponentConfig;

export enum VideoState {
	Initial = 'initial',
	Playing = 'playing',
	Paused = 'paused',
	Completed = 'completed',
	Failed = 'failed'
}

export enum VideoEvent {
	StateChanged = 'state_changed',
	VolumeChanged = 'volume_changed',
	InteractionChanged = 'interaction_changed'
}

const INTERACTION_DURATION = 3000;

export default class Video extends BaseComponent {
	autoPlay: boolean;
	startMuted: boolean;

	muted: boolean;
	interacted: boolean;

	state: VideoState;
	events: EventTarget;

	#manualPause: boolean;
	#listenersRegistered: boolean;

	imaManager: IMAManager;

	#interactionTimeout: ReturnType<typeof setTimeout>;

	constructor(config: VideoConfig, renderConfig: RenderConfig) {
		super(config);

		this.autoPlay = config.autoPlay ?? renderConfig?.video?.autoPlay ?? true;
		this.startMuted = this.autoPlay ? true : config.startMuted ?? renderConfig?.video?.startMuted ?? true;
		this.events = new EventTarget();
		this.state = VideoState.Initial;
		this.muted = this.startMuted;
		this.interacted = false;

		this.#manualPause = false;
		this.#listenersRegistered = false;
	}

	render(renderContext: RenderContext): HTMLElement {
		const container = document.createElement('div');

		renderContext.style.style(container, {
			'position': 'relative',
			'width': '100%',
			'maxHeight': '100%',
			'pointerEvents': 'all', // for the interactivity checker
			'zIndex': '0', // otherwise it'll change stacking order in box due to IMA
		});

		container.tabIndex = 0;

		this.imaManager = new IMAManager({
			container,
			content: renderContext.adObject.getValue(ValueType.video),
			startMuted: this.startMuted,
			autoPlay: this.autoPlay,
			onReady: () => {
				if (!this.#listenersRegistered) {
					this.#addViewabilityObservers(container);
					this.#addInteractListener(container);
					this.#addResizeListener(container);
					this.#listenersRegistered = true;
				}

				if (this.state === VideoState.Completed) {
					this.imaManager.play(this.muted);
				}
			},
			onStart: () => {
				container.style.display = '';

				const size = this.imaManager.getVideoSize();

				container.style.paddingBottom = size.width === 0 ? '' : `${size.height / size.width * 100}%`;

				this.imaManager.resize(container.clientWidth, container.clientHeight);

				this.#setState(VideoState.Playing);
			},
			onStop: () => {
				container.style.display = '';

				this.#setState(VideoState.Paused);
			},
			onComplete: () => {
				container.style.display = 'none';

				this.#setState(VideoState.Completed);
			},
			onError: () => {
				container.style.display = 'none';

				this.#setState(VideoState.Failed);
			}
		}, renderContext);

		return container;
	}

	play() {
		this.#manualPause = false;

		if (this.state === VideoState.Completed) {
			this.startMuted = this.muted;
			this.imaManager.reset();
			// Play triggered in onReady thanks to autoplay
		} else {
			this.imaManager.play(this.muted);
		}
	}

	pause() {
		this.#manualPause = true;
		this.imaManager.pause();
	}

	mute() {
		this.muted = true;
		this.imaManager.setVolume(0);
		this.events.dispatchEvent(new Event(VideoEvent.VolumeChanged));
	}

	unmute() {
		this.muted = false;
		this.imaManager.setVolume(1);
		this.events.dispatchEvent(new Event(VideoEvent.VolumeChanged));
	}

	getRemainingTime(): number {
		return this.imaManager.getRemainingTime();
	}

	#addInteractListener(container: HTMLElement) {
		container.addEventListener('click', (e) => {
			this.#toggleInteract();
			e.stopPropagation();
			e.preventDefault();
		});
	}

	#addViewabilityObservers(container: HTMLElement) {
		let inView = false;

		new IntersectionObserver((entryList) => {
			inView = entryList.some(e => e.isIntersecting);
			this.#onViewabilityChange(inView, document.visibilityState === 'visible');
		}, {'threshold': [0]}).observe(container);

		document.addEventListener('visibilitychange', () => {
			this.#onViewabilityChange(inView, document.visibilityState === 'visible');
		});
	}

	#onViewabilityChange(inView: boolean, isScreenVisible: boolean) {
		switch (this.state) {
			case VideoState.Initial: {
				if (inView && this.autoPlay) {
					this.imaManager.play(this.muted);
				}

				return;
			}

			case VideoState.Playing:
			case VideoState.Paused: {
				if (inView && isScreenVisible) {
					if (!this.#manualPause) {
						this.imaManager.play(this.muted);
					}
				} else {
					this.imaManager.pause();
				}
			}
		}
	}

	#addResizeListener(container: HTMLElement) {
		new ResizeObserver(() => {
			this.imaManager.resize(container.clientWidth, container.clientHeight);
		}).observe(container);
	}

	#setState(state: VideoState) {
		this.state = state;

		this.events.dispatchEvent(new Event(VideoEvent.StateChanged));
	}

	#toggleInteract() {
		this.interacted = !this.interacted;
		this.events.dispatchEvent(new Event(VideoEvent.InteractionChanged));

		if (this.interacted) {
			this.#interactionTimeout = setTimeout(() => {
				this.#toggleInteract();
			}, INTERACTION_DURATION);
		} else {
			clearTimeout(this.#interactionTimeout);
		}
	}
}

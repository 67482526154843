import BaseLabel, {BaseLabelConfig} from './subcomponents/BaseLabel';
import NativeAdObject from '../model/NativeAdObject';

export type RichLabelConfig = BaseLabelConfig & {
	type: 'richLabel'
};

export default class RichLabel extends BaseLabel {
	constructor(config: RichLabelConfig) {
		super(config);
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = super.render(renderContext);

		renderContext.style.style(el, {
			'pointerEvents': 'initial',
			'& a': {
				color: 'inherit'
			}
		});

		return el;
	}

	static #clean(node: DocumentFragment): DocumentFragment {
		node.querySelectorAll('a').forEach((anchor) => {
			if (!anchor.hasAttribute('target')) anchor.setAttribute('target', '_blank');
			if (!anchor.hasAttribute('rel')) anchor.setAttribute('rel', 'noopener noreferrer nofollow');
		});

		return node;
	}

	getText(adObject: NativeAdObject, customValueTypeIndex?: number): DocumentFragment {
		const value = this.#getSafeValue(adObject, customValueTypeIndex),
			formatted = this.textFormat.replace('%s', value);

		return RichLabel.#clean(document.createRange().createContextualFragment(formatted));
	}


	#getSafeValue(adObject: NativeAdObject, customValueTypeIndex?: number): string {
		const wrapper = document.createElement('p');

		wrapper.textContent = adObject.getValue(this.valueType, customValueTypeIndex);

		return wrapper.innerHTML;
	}
}

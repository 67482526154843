export const TRACKING_EVENTS = {
	SLIDE_IMPRESSIONS: 'slideImpressions',
	SLIDE_CLICKS: 'slideClicks',
	LEADFORM_IMPRESSION: 'leadformImpression',
	LEADFORM_CLOSE: 'leadformClose',
	LEADFORM_CANCEL: 'leadformCancel',
	LEADFORM_SUBMIT: 'leadformSubmit',
	LEADFORM_SUBMIT_FAILURE: 'leadformSubmitFailure',
	LEADFORM_CLICKOUT: 'leadformClickout'
};

export const EVENT_TYPES = {
	CLICK: 'ad_click',
	IMPRESSION: 'ad_impression'
};
import BaseComponent, {BaseComponentConfig} from '../BaseComponent';
import {getBorderString, getColorString, getSpacingString} from '../../util/style-util';
import {findInTree} from '../../util/component-util';
import ValidationLabel from '../ValidationLabel';
import {CSSInterpolation} from '@emotion/css/create-instance';
import {validateInput} from '../../../util/inputValidation';
import {Component} from '../index';

const DEFAULT_SIZE = 25,
	DEFAULT_BACKGROUND_COLOR = '#eee',
	DEFAULT_COLOR = '#2196f3';

export type LeadformCheckboxConfig = {
	type: 'leadformCheckbox'
	margin?: Spacing
	padding?: Spacing
	size?: Size
	color?: Color
	backgroundColor?: Color
	cornerRadius?: number
	borderWidth?: number
	borderColor?: Color
	fieldName?: string
	validation?: Validation
	onError?: {
		borderColor?: Color
	}
} & BaseComponentConfig;

export default class LeadformCheckbox extends BaseComponent {
	type: 'leadformCheckbox';
	margin?: Spacing;
	padding?: Spacing;
	width: number;
	height: number;
	color?: Color;
	backgroundColor?: Color;
	cornerRadius?: number;
	borderWidth?: number;
	borderColor?: Color;
	fieldName?: string;
	validation?: Validation;
	onError?: {
		borderColor?: Color
	};
	element: HTMLInputElement;
	validationLabel: ValidationLabel | null;

	constructor(config: LeadformCheckboxConfig) {
		super(config);

		this.margin = config.margin;
		this.padding = config.padding;
		this.width = config.size?.width ?? DEFAULT_SIZE;
		this.height = config.size?.height ?? DEFAULT_SIZE;
		this.color = config.color;
		this.backgroundColor = config.backgroundColor;
		this.cornerRadius = config.cornerRadius;
		this.borderWidth = config.borderWidth;
		this.borderColor = config.borderColor;
		this.fieldName = config.fieldName;
		this.validation = config.validation;
		this.onError = config.onError;
	}

	render(renderContext: RenderContext): HTMLElement {
		const div = document.createElement('label'),
			input = document.createElement('input'),
			customCheckbox = document.createElement('div');

		this.element = input;

		input.type = 'checkbox';
		input.name = this.fieldName ?? '';

		renderContext.style.style(div, {
			'position': 'relative',
			'width': this.#getContainerWidth(),
			'height': this.#getContainerHeight(),
			'margin': getSpacingString(this.margin)
		});

		// Create a custom checkbox
		renderContext.style.style(customCheckbox, {
			'display': 'block',
			'position': 'absolute',
			'top': 0,
			'left': 0,
			'padding': getSpacingString(this.padding),
			'height': this.height,
			'width': this.width,
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_BACKGROUND_COLOR,
			'border': typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined' ? getBorderString(renderContext, {
				'width': this.borderWidth,
				'color': this.borderColor
			}) : 'none',
			'userSelect': 'none',
			'pointerEvents': 'all',
			'cursor': 'pointer',

			':after': {
				'content': '""',
				'position': 'absolute',
				'display': 'none',
				'width': this.#getCheckmarkWidth(),
				'top': '2px',
				'left': '2px',
				'height': this.#getCheckmarkHeight(),
				'border': 'solid white',
				'borderWidth': '0px 2px 2px 0px',
				'transformOrigin': 'left bottom',
				'transform': `translate(${this.padding?.left ?? 0}px, ${this.#getTranslateY()}px) rotate(45deg)`,
			},

			'input:checked ~ &': {
				'backgroundColor': getColorString(renderContext, this.color) ?? DEFAULT_COLOR
			},
			'input:checked ~ &:after': {
				'display': 'block'
			},
			'input:focus ~ &': {
				'outline': [
					'1px dotted #212121', // Non-webkit fallback
					'1px auto -webkit-focus-ring-color'
				],
			}
		});

		// Hide the browsers default container
		renderContext.style.style(input, {
			'pointerEvents': 'all',
			'position': 'absolute',
			'opacity': 0,
			'cursor': 'pointer',
			'height': 0,
			'width': 0,
		});

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(customCheckbox, {
				'borderRadius': `${this.cornerRadius}px`
			});
		}

		div.appendChild(input);
		div.appendChild(customCheckbox);

		if (this.onError) {
			const errorStyles: CSSInterpolation = {};

			if (typeof this.onError.borderColor !== 'undefined') {
				errorStyles.border = getBorderString(renderContext, {
					'width': this.borderWidth,
					'color': this.onError.borderColor
				});
			}

			renderContext.style.style(customCheckbox, {
				'input[state="error"] ~ &': errorStyles
			});
		}

		if (this.validation) {
			this.#addValidation(renderContext.componentTree);
		}

		return div;
	}

	getValue(): boolean {
		return this.element.checked;
	}

	validate(): boolean {
		return validateInput(this.element, this.validation as Validation, this.validationLabel as ValidationLabel);
	}

	#addValidation(componentTree: Component): void {
		this.validationLabel = findInTree<ValidationLabel>(componentTree, (c: ValidationLabel) => c.type === 'validationLabel' && c.errorId === this.validation?.id);
	}

	#getContainerWidth() {
		return this.width + (this.padding?.left ?? 0) + (this.padding?.right ?? 0) + 2 * (this.borderWidth ?? 0);
	}

	#getContainerHeight() {
		return this.height + (this.padding?.top ?? 0) + (this.padding?.bottom ?? 0) + 2 * (this.borderWidth ?? 0);
	}

	#getCheckmarkWidth() {
		// Checkmark is actually a rectangle that is rotated 45 degrees inside a square container,
		// so width of this rectangle can be found by applying the Pythagorean theorem
		const widthAndBorder = this.#applyPythagoreanTheorem(this.width / 3, this.width / 3);
		return widthAndBorder - 2 - 2; // 2 for the width of each border of the rectangle
	}

	#getCheckmarkHeight() {
		// Checkmark is actually a rectangle that is rotated 45 degrees inside a square container,
		// so height of this rectangle can be found by applying the Pythagorean theorem
		const heightAndBorder = this.#applyPythagoreanTheorem(this.height / 3 * 2, this.height / 3 * 2);
		return heightAndBorder - 2 - 2; // 2 for the width of each border of the rectangle
	}

	#getTranslateY() {
		// The checkmark/rectangle is rotated on the bottom left corner, so is partly below the checkbox
		const partBelowCheckbox = this.height / 3,
			// 2 sides of the rectangle aren't visible, so it needs to move up to fill this empty space
			spacerToCenter = this.height / 6 - this.#applyPythagoreanTheorem(2, 2),
			paddingTop = this.padding?.top ?? 0;
		return -(partBelowCheckbox + spacerToCenter) + paddingTop;
	}

	#applyPythagoreanTheorem(a: number, b: number) {
		return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
	}
}

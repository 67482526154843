import {DEFAULT_COLORS, getColorString, getSpacingString} from '../../util/style-util';

export type CarouselProgressIndicatorConfig = {
	// style?: string
	spacing?: number
	padding?: Spacing
	activeColor?: Color
	inactiveColor?: Color
	activeHeight?: number
	inactiveHeight?: number
};

export default class CarouselProgressIndicator {
	// style: string
	spacing: number;
	padding?: Spacing;
	activeColor?: Color;
	inactiveColor?: Color;
	activeHeight: number;
	inactiveHeight: number;
	segments: Array<HTMLElement>;

	constructor(config: CarouselProgressIndicatorConfig) {
		// Currently unused, would be used to define other progress indicators from a generic indicator
		// this.style = config.style ?? 'progress';

		this.spacing = config.spacing ?? 0;

		this.inactiveColor = config.inactiveColor;
		this.activeColor = config.activeColor;

		this.inactiveHeight = config.inactiveHeight ?? 0;
		this.activeHeight = config.activeHeight ?? 0;

		this.padding = config.padding;

		this.segments = [];
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('div');

		renderContext.style.style(el, {
			'display': 'flex',
			'height': `${Math.max(this.activeHeight, this.inactiveHeight)}px`,
			'padding': getSpacingString(this.padding),
		});

		for (let i = 0; i <= renderContext.adObject.valueTypeMaxIdx; i++) {
			const segmentEl = this.#createSegment(renderContext, i);

			el.appendChild(segmentEl);
			this.segments.push(segmentEl);
		}

		return el;
	}

	#createSegment(renderContext: RenderContext, index: number): HTMLElement {
		const inactiveEl = document.createElement('div'),
			activeEl = document.createElement('div');

		renderContext.style.style(inactiveEl, {
			'position': 'relative',
			'flexGrow': '1',
			'height': `${this.inactiveHeight}px`,
			'backgroundColor': getColorString(renderContext, this.inactiveColor) ?? DEFAULT_COLORS.TRANSPARENT,
		});

		if (index > 0) {
			renderContext.style.style(inactiveEl, {
				'marginLeft': `${this.spacing}px`,
			});
		}

		renderContext.style.style(activeEl, {
			'position': 'absolute',
			'left': '0',
			'bottom': '0', // Intentionally aligned to bottom side, not centered vertically.
			'height': `${this.activeHeight}px`,
			'width': '0%',
			'backgroundColor': getColorString(renderContext, this.activeColor) ?? DEFAULT_COLORS.TRANSPARENT,
		});

		inactiveEl.appendChild(activeEl);

		return inactiveEl;
	}

	setProgress(progress: number): void {
		this.segments.forEach((segment, idx) => {
			const activeEl = segment.childNodes[0] as HTMLElement;

			activeEl.style.width = idx < progress ? '100%' : '0%';
			// Current segment should transition, but also next one when going backwards
			activeEl.style.transition = idx + 1 === progress || idx === progress ? 'width 0.33s linear' : '';
		});
	}
}

import NativeAdObject, {ValueType} from '../renderer/model/NativeAdObject';
import Leadform from '../renderer/components/leadform/Leadform';
import Carousel from '../renderer/components/Carousel';
import Label from '../renderer/components/Label';
import {findInTree} from '../renderer/util/component-util';
import Button from '../renderer/components/Button';
import {EVENT_TYPES, TRACKING_EVENTS} from '../renderer/model/tracking';
import logger from '../util/logger';

export function trackCarouselImpression(slideIdx: number, renderContext: RenderContext, carousel: Carousel): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.SLIDE_IMPRESSIONS))
		return;

	const ctaButton = findInTree<Button>(carousel, (c) => c.type === 'button'),
		cta = ctaButton?.label?.getText(renderContext.adObject, slideIdx).textContent as string,
		trackingEvent = _createCarouselTrackingEvent(EVENT_TYPES.IMPRESSION, 'slide-impression', slideIdx, carousel, renderContext, cta);
	_sendTrackingEvent(trackingEvent);
}

export function trackCarouselClick(renderContext: RenderContext, carousel: Carousel): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.SLIDE_CLICKS))
		return;

	carousel.slides.forEach((pageEl, index) => {
		pageEl.addEventListener('click', () => {
			const ctaButton = findInTree<Button>(carousel, (c) => c.type === 'button'),
				cta = ctaButton?.label?.getText(renderContext.adObject, index).textContent as string,
				slideIndex = pageEl.getAttribute('data-carousel-slide') as string,
				trackingEvent = _createCarouselTrackingEvent(EVENT_TYPES.CLICK, 'slide-click', slideIndex, carousel, renderContext, cta);
			_sendTrackingEvent(trackingEvent);
		});
	});
}

export function trackLeadformImpression(renderContext: RenderContext, leadform: Leadform, label: string | Label): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.LEADFORM_IMPRESSION))
		return;

	let ctaText: string;
	if (typeof label === 'string') {
		ctaText = label;
	} else {
		ctaText = label.getText(renderContext.adObject).textContent as string;
	}
	const trackingEvent = _createLeadformTrackingEvent(EVENT_TYPES.IMPRESSION, 'leadform-impression', leadform, renderContext, ctaText);
	_sendTrackingEvent(trackingEvent);
}

export function trackLeadformSubmit(renderContext: RenderContext, leadform: Leadform, label: Label): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.LEADFORM_SUBMIT))
		return;

	const ctaText = label.getText(renderContext.adObject).textContent as string,
		trackingEvent = _createLeadformTrackingEvent(EVENT_TYPES.CLICK, 'leadform-submit-click', leadform, renderContext, ctaText);
	_sendTrackingEvent(trackingEvent);
}

export function trackLeadformSubmitFailure(renderContext: RenderContext, leadform: Leadform, label: Label): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.LEADFORM_SUBMIT_FAILURE))
		return;

	const ctaText = label.getText(renderContext.adObject).textContent as string,
		trackingEvent = _createLeadformTrackingEvent(EVENT_TYPES.CLICK, 'leadform-submit-failure-click', leadform, renderContext, ctaText);
	_sendTrackingEvent(trackingEvent);
}

export function trackLeadformCancel(renderContext: RenderContext, leadform: Leadform): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.LEADFORM_CANCEL))
		return;

	const trackingEvent = _createLeadformTrackingEvent(EVENT_TYPES.CLICK, 'leadform-cancel-click', leadform, renderContext, '');
	_sendTrackingEvent(trackingEvent);
}

export function trackLeadformClose(renderContext: RenderContext, leadform: Leadform, label: Label): void {
	if (!_isTrackingEnabled(renderContext, TRACKING_EVENTS.LEADFORM_CLOSE))
		return;

	const ctaText = label.getText(renderContext.adObject).textContent as string,
		trackingEvent = _createLeadformTrackingEvent(EVENT_TYPES.CLICK, 'leadform-close-click', leadform, renderContext, ctaText);
	_sendTrackingEvent(trackingEvent);
}

export function trackClickout(renderContext: RenderContext): void {
	const leadformComponent = findInTree<Leadform>(renderContext.componentTree, (c) => c.type === 'leadform') as Leadform;

	if (!leadformComponent || !_isTrackingEnabled(renderContext, TRACKING_EVENTS.LEADFORM_CLICKOUT))
		return;

	const trackingEvent = _createLeadformTrackingEvent(
		EVENT_TYPES.CLICK,
		'leadform-clickout-click',
		leadformComponent,
		renderContext,
		renderContext.adObject.getValue(ValueType.callToAction)
	);
	_sendTrackingEvent(trackingEvent);
}

function _isTrackingEnabled(renderContext: RenderContext, adInteraction: string): boolean {
	return renderContext.renderConfig.tracking?.enabledEvents?.includes(adInteraction) ?? false;
}

function _createLeadformTrackingEvent(event: string, adInteraction: string, leadform: Leadform, renderContext: RenderContext, ctaText: string): LeadformTrackingEvent {
	return {
		'event': event,
		'adProduct': 'seamless-leadform',
		'adInteraction': adInteraction,
		'pageIndex': `${leadform.currentPage + 1}`,
		'pageCount': `${leadform.contentPages.length}`,
		'auctionId': _getAuctionId(renderContext),
		'ctaText': ctaText,
		'timestamp': new Date().toISOString()
	};
}

function _createCarouselTrackingEvent(event: string, adInteraction: string, slideIdx: string | number, carousel: Carousel, renderContext: RenderContext, cta?: string): CarouselTrackingEvent {
	return {
		'event': event,
		'adProduct': 'seamless-carousel',
		'adInteraction': adInteraction,
		'slideIndex': `${slideIdx}`,
		'slideCount': `${carousel.slides.length}`,
		'auctionId': _getAuctionId(renderContext),
		'ctaText': cta ?? '',
		'timestamp': new Date().toISOString()
	};
}

function _getAuctionId(renderContext: RenderContext): string {
	return renderContext.renderConfig.context?.slot?.adResponse?.auctionId ?? '';
}

function _sendTrackingEvent(trackingEvent: TrackingEvent): void {
	if (window.dataLayer) {
		window.dataLayer.push(trackingEvent);
	} else {
		logger.warn('Could not send tracking event, no datalayer found. Event:', trackingEvent);
	}
}

export function fireClickTrackers(nativeAdObject: NativeAdObject) {
	nativeAdObject.clickTrackers?.forEach(url => {
		fetch(url)
			.catch(e => logger.warn('Click tracking failed for', nativeAdObject, e));
	});
}

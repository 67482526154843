import BaseLabel, {BaseLabelConfig} from './subcomponents/BaseLabel';

export type LabelConfig = BaseLabelConfig & {
	type: 'label'
};

export default class Label extends BaseLabel {
	constructor(config: LabelConfig, isSubcomponent = false) {
		super(config, isSubcomponent);
	}
}

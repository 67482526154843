import ValidationLabel from '../renderer/components/ValidationLabel';
import {findAllTypesInTree} from '../renderer/util/component-util';
import LeadformInput from '../renderer/components/leadform/LeadformInput';
import LeadformCheckbox from '../renderer/components/leadform/LeadformCheckbox';
import {Component} from '../renderer/components';

export function validateInput(element: HTMLInputElement | HTMLTextAreaElement, validation: Validation, validationLabel: ValidationLabel): boolean {
	if (validation?.required && _isEmptyInput(element)) {
		validationLabel.showError(validation.messageRequired ?? '');
		element.setAttribute('state', 'error');

		return false;
	}

	if (validation?.regex && _isInvalidFormat(element, validation.regex)) {
		validationLabel.showError(validation.messageValidation ?? '');
		element.setAttribute('state', 'error');

		return false;
	}

	validationLabel.hideError();
	element.removeAttribute('state');

	return true;
}

function _isEmptyInput(element: HTMLInputElement | HTMLTextAreaElement): boolean {
	return (element.type === 'text' || element.type === 'textarea') && element.value.length <= 0
		|| element.type === 'checkbox' && !(element as HTMLInputElement).checked;
}

function _isInvalidFormat(element: HTMLInputElement | HTMLTextAreaElement, regex: string): boolean {
	return !new RegExp(regex).test(element.value);
}

function getFormInputs(componentTree: Component) {
	return findAllTypesInTree<LeadformInput | LeadformCheckbox>(componentTree, ['leadformInput', 'leadformCheckbox']);
}


export function validateFormInputs(componentTree: Component): boolean {
	return getFormInputs(componentTree)
		.filter((component) => component.validation)
		.reduce((success, field) => field.validate() && success, true);
}


export function getFormData(componentTree: Component): Record<string, unknown> {
	return Object.fromEntries(
		getFormInputs(componentTree)
			.map((field) => [field.fieldName, field.getValue()])
	);
}

import {findInTree, ShowOnStates, showOnVideoStates} from '../util/component-util';
import Video, {VideoEvent} from './Video';
import Label, {LabelConfig} from './Label';

export type VideoTimerConfig = Omit<LabelConfig, 'type'> & {
	type: 'videoTimer'
	showOn?: ShowOnStates
};

const UPDATE_INTERVAL = 300;

export default class VideoTimer extends Label {
	showOn?: ShowOnStates;

	constructor(config: VideoTimerConfig) {
		super(config as unknown as LabelConfig);

		this.showOn = config.showOn;
	}

	render(renderContext: RenderContext): HTMLElement {
		const video = findInTree<Video>(renderContext.componentTree, (c) => c.type === 'video');

		if (video == null) {
			throw new Error('Could not find a video for the VideoTimer');
		}

		const label = super.render(renderContext);

		this.#updateText(video, label);
		video.events.addEventListener(VideoEvent.StateChanged, () => this.#updateText(video, label));
		setInterval(() => this.#updateText(video, label), UPDATE_INTERVAL);

		if (Array.isArray(this.showOn)) {
			showOnVideoStates(label, video, this.showOn);
		}

		return label;
	}

	#updateText(video: Video, label: HTMLElement) {
		const text = this.#getRemainingTime(video);

		this.setText(document.createTextNode(text));
		label.style.display = text ? '' : 'none';
	}

	#getRemainingTime(video: Video): string {
		function _leadingZero(str: string) {
			return `0${str}`.slice(Math.min(-str.length, -2));
		}

		const remainingTime = Math.floor(video.getRemainingTime());

		if (remainingTime <= 0) {
			return '';
		}

		const minutes = Math.floor(remainingTime / 60),
			seconds = remainingTime % 60;

		return `${_leadingZero('' + minutes)}:${_leadingZero('' + seconds)}`;
	}
}

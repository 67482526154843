import {DEFAULT_COLORS, getBorderString, getColorString, getSpacingString} from '../../util/style-util';
import BaseComponent, {BaseComponentConfig} from '../BaseComponent';
import {CSSInterpolation} from '@emotion/css/create-instance';

export type BaseButtonConfig = {
	padding?: Spacing
	backgroundColor?: Color
	borderColor?: Color
	borderWidth?: number
	cornerRadius?: number
	size?: Size
	onHover?: {
		backgroundColor?: Color
		borderColor?: Color
		borderWidth?: number
		cornerRadius?: number
	}
} & BaseComponentConfig;

export default class Button extends BaseComponent {
	margin?: Spacing;
	width?: number;
	height?: number;

	backgroundColor?: Color;
	borderWidth?: number;
	borderColor?: Color;
	cornerRadius?: number;
	onHover?: {
		backgroundColor?: Color;
		borderColor?: Color;
		borderWidth?: number;
		cornerRadius?: number;
	};

	constructor(config: BaseButtonConfig) {
		super(config);

		this.margin = config.padding; // This should be margin _around_ the button. The label handles padding _in_ the button
		this.width = config.size?.width;
		this.height = config.size?.height;

		this.backgroundColor = config.backgroundColor;
		this.borderWidth = config.borderWidth;
		this.borderColor = config.borderColor;
		this.cornerRadius = config.cornerRadius;
		this.onHover = config.onHover;
	}

	render(renderContext: RenderContext): HTMLElement {
		const el = document.createElement('button');

		el.type = 'button';
		renderContext.style.style(el, {
			'verticalAlign': 'middle',
			'cursor': 'pointer',
			'textAlign': 'center',
			'pointerEvents': 'all' // Required to capture click events/hover
		});

		renderContext.style.style(el, {
			'padding': 0,
			'margin': getSpacingString(this.margin),
			'backgroundColor': getColorString(renderContext, this.backgroundColor) ?? DEFAULT_COLORS.TRANSPARENT,
			'border': typeof this.borderColor !== 'undefined' || typeof this.borderWidth !== 'undefined' ? getBorderString(renderContext, {
				'width': this.borderWidth,
				'color': this.borderColor
			}) : 'none'
		});

		if (typeof this.cornerRadius === 'number') {
			renderContext.style.style(el, {
				'borderRadius': `${this.cornerRadius}px`
			});
		}

		if (typeof this.width === 'number') {
			renderContext.style.style(el, {
				'flexShrink': '0',
				'flexGrow': '0',
				'width': `${this.width}px`
			});
		}

		if (typeof this.height === 'number') {
			renderContext.style.style(el, {
				'flexShrink': '0',
				'flexGrow': '0',
				'height': `${this.height}px`
			});
		}

		if (this.onHover) {
			const hoverStyles: CSSInterpolation = {};

			if (typeof this.onHover.borderColor !== 'undefined' || typeof this.onHover.borderWidth === 'number') {
				hoverStyles.border = getBorderString(renderContext, {
					'width': this.onHover.borderWidth ?? this.borderWidth,
					'color': this.onHover.borderColor ?? this.borderColor
				});
			}

			if (typeof this.onHover.backgroundColor !== 'undefined') {
				hoverStyles.backgroundColor = getColorString(renderContext, this.onHover.backgroundColor) as string;
			}

			if (typeof this.onHover.cornerRadius === 'number') {
				hoverStyles.borderRadius = `${this.onHover.cornerRadius}px`;
			}

			renderContext.style.style(el, {
				'&:hover': hoverStyles
			});
		}

		return el;
	}
}

import {isConditionActive} from './condition-util';

export function getTemplateNameFromProduct(product: NativeTemplateProduct, renderConfig: RenderConfig, containerEl?: HTMLElement): string | null {
	const props = {
			viewportWidth: Math.floor(window.innerWidth),
			containerWidth: containerEl ? Math.floor(containerEl.getBoundingClientRect().width) : undefined,
			...renderConfig.context,
			...renderConfig.overrides,
		},
		matchingSpec = product.templates.find((spec) =>
			spec.conditions?.some((cg) => cg.every(c => isConditionActive(c, props))) ?? true);

	return matchingSpec?.template ?? null;
}

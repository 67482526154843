export function waitForDOMAttachment(element: HTMLElement, root: ShadowRoot): Promise<void> {
	function _isConnected() {
		return document.contains(root.host) && root.contains(element);
	}

	return new Promise((resolve) => {
		if (_isConnected()) {
			resolve();

			return;
		}

		const observer = new MutationObserver(() => {
			if (_isConnected()) {
				observer.disconnect();
				resolve();
			}
		});

		observer.observe(document, {childList: true, subtree: true});
		observer.observe(root, {childList: true, subtree: true});
	});
}

export function loadLibrary(libraryUrl: string, libraryReadyCheck: () => boolean, retryDelay = 10): Promise<void> {
	if (document.head.querySelector(`script[src*="${libraryUrl}"]`) === null) {
		const scriptEl = document.createElement('script');

		scriptEl.src = libraryUrl;

		document.head.appendChild(scriptEl);
	}

	return new Promise((resolve) => {
		if (libraryReadyCheck()) {
			return resolve();
		}

		const interval = setInterval(() => {
			if (libraryReadyCheck()) {
				clearInterval(interval);
				resolve();
			}
		}, retryDelay);
	});
}

export function waitForInViewport(element: HTMLElement): Promise<void> {
	return new Promise((resolve) => {
		const observer = new IntersectionObserver((entryList) => {
			for (const entry of entryList) {
				if (entry.isIntersecting) {
					observer.disconnect();
					resolve();
					return;
				}
			}
		}, {
			rootMargin: '0px',
			threshold: [1]
		});

		observer.observe(element);
	});
}

import Unknown from './Unknown';
import Box, {BoxConfig} from './Box';
import Button, {ButtonConfig} from './Button';
import Carousel, {CarouselConfig} from './Carousel';
import Column, {ColumnConfig} from './Column';
import Header, {HeaderConfig} from './Header';
import Image, {ImageConfig} from './Image';
import Label, {LabelConfig} from './Label';
import Line, {LineConfig} from './Line';
import LinearGradient, {LinearGradientConfig} from './LinearGradient';
import Row, {RowConfig} from './Row';
import Spacer, {SpacerConfig} from './Spacer';
import Video, {VideoConfig} from './Video';
import VideoPlayPauseButton, {VideoPlayPauseButtonConfig} from './VideoPlayPauseButton';
import VideoMuteButton, {VideoMuteButtonConfig} from './VideoMuteButton';
import VideoTimer, {VideoTimerConfig} from './VideoTimer';
import Leadform, {LeadformConfig} from './leadform/Leadform';
import LeadformInput, {LeadformInputConfig} from './leadform/LeadformInput';
import LeadformSubmit, {LeadformSubmitConfig} from './leadform/LeadformSubmit';
import LeadformGoToPage, {LeadformGoToPageConfig} from './leadform/LeadformGoToPage';
import LeadformClose, {LeadformCloseConfig} from './leadform/LeadformClose';
import LeadformCheckbox, {LeadformCheckboxConfig} from './leadform/LeadformCheckbox';
import ValidationLabel, {ValidationLabelConfig} from './ValidationLabel';
import RichLabel, {RichLabelConfig} from './RichLabel';
import DSA, {DSAConfig} from './DSA';

export type Component = Box
	| Button
	| Carousel
	| Column
	| Header
	| Image
	| Label
	| RichLabel
	| Line
	| LinearGradient
	| Row
	| Spacer
	| Video
	| VideoPlayPauseButton
	| VideoMuteButton
	| VideoTimer
	| Leadform
	| LeadformInput
	| LeadformSubmit
	| LeadformGoToPage
	| LeadformClose
	| LeadformCheckbox
	| ValidationLabel
	| DSA
	| Unknown;

export type ComponentConfig = BoxConfig
	| ButtonConfig
	| CarouselConfig
	| ColumnConfig
	| HeaderConfig
	| ImageConfig
	| LabelConfig
	| RichLabelConfig
	| LineConfig
	| LinearGradientConfig
	| RowConfig
	| SpacerConfig
	| VideoConfig
	| VideoPlayPauseButtonConfig
	| VideoTimerConfig
	| VideoMuteButtonConfig
	| LeadformConfig
	| LeadformInputConfig
	| LeadformSubmitConfig
	| LeadformGoToPageConfig
	| LeadformCloseConfig
	| LeadformCheckboxConfig
	| ValidationLabelConfig
	| DSAConfig;

export function buildComponent(definition: ComponentConfig, renderConfig: RenderConfig): Component {
	switch (definition.type) {
		case 'box':
			return new Box(definition, renderConfig);

		case 'carousel':
			return new Carousel(definition, renderConfig);

		case 'row':
			return new Row(definition, renderConfig);

		case 'column':
			return new Column(definition, renderConfig);

		case 'button':
			return new Button(definition);

		case 'header':
			return new Header(definition);

		case 'image':
			return new Image(definition);

		case 'label':
			return new Label(definition);

		case 'richLabel':
			return new RichLabel(definition);

		case 'line':
			return new Line(definition);

		case 'linearGradient':
			return new LinearGradient(definition);

		case 'spacer':
			return new Spacer(definition);

		case 'video':
			return new Video(definition, renderConfig);

		case 'videoPlayPause':
			return new VideoPlayPauseButton(definition);

		case 'videoMute':
			return new VideoMuteButton(definition);

		case 'videoTimer':
			return new VideoTimer(definition);

		case 'leadform':
			return new Leadform(definition, renderConfig);

		case 'leadformInput':
			return new LeadformInput(definition);

		case 'leadformSubmit':
			return new LeadformSubmit(definition);

		case 'leadformGoToPage':
			return new LeadformGoToPage(definition);

		case 'leadformClose':
			return new LeadformClose(definition);

		case 'leadformCheckbox':
			return new LeadformCheckbox(definition);

		case 'validationLabel':
			return new ValidationLabel(definition);

		case 'dsa':
			return new DSA(definition, renderConfig);

		default:
			return new Unknown(definition);
	}
}
